import React from "react"

import * as style from "./BiographyBox.module.scss"

import Link from "../UI/Link"

const BiographyBox = ({ index, jsonLDSchema, publishedDate }) => {
  const { medicalSchema } = jsonLDSchema || {}
  const date = new Date(publishedDate).toLocaleDateString("en-GB")

  return (
    <div id="authors" className={style.biograpyContainer}>
      <div className="ps-2 d-flex justify-content-center flex-column">
        <div className="d-block ">
        {medicalSchema && medicalSchema.reviewers && (
          <span className={`${style.boldCls} d-inline-block`}>
            Medically Reviewed by:
          </span>
        )}
          {medicalSchema &&
            medicalSchema.reviewers &&
            medicalSchema.reviewers.map((reviewer, i, arr) => {
              if (reviewer.biographyLink) {
                return (
                    <>
                      <Link  style={{color: "#e52e92"}}
                          classes="d-inline text-secondary ps-1 pe-1"
                          key={reviewer.name}
                          link={
                            reviewer.biographyLink
                          }
                      > {reviewer.name}
                      </Link>
                      {i + 1 === arr.length ? (
                          ""
                      ) : (
                          <span className="ps-1">&amp;</span>
                      )}
                    </>
                )
              }
              else {
                return (
                    <>
                      {reviewer.name}
                      {i + 1 === arr.length ? (
                          ""
                      ) : (
                              <span className="ps-1">&amp;</span>
                          )
                      }
                    </>
                )
              }
            })}
        </div>
        <div>
          {medicalSchema && medicalSchema.lastReview && (
            <>
              <span className={`${style.boldCls} d-inline-block my-1`}>
                Last Reviewed:
              </span>
              <span>
              {medicalSchema.lastReview}
              </span>
            </>
          )}
        </div>
        <div>
          {medicalSchema && medicalSchema.nextReview && (
            <>
              <span className={`${style.boldCls} d-inline-block pr-2 mb-1`}>
                Next review date:
              </span>
              <span>
                {medicalSchema.nextReview}
              </span>
            </>
          )}
        </div>

        <div>
         { medicalSchema.authors &&  <span className={`${style.boldCls} d-inline-block`}>Written by: </span>}
          {medicalSchema &&
            medicalSchema.authors &&
            medicalSchema.authors.map((author, i, arr) => {
              if (author.biographyLink) {
                return (
                    <>
                      <Link
                          style={{color: "#e52e92"}}
                          classes="d-inline text-secondary ps-1 pe-1"
                          key={author.name}
                          link={
                             author.biographyLink
                          }
                      > {author.name}
                      </Link>
                      {i + 1 === arr.length ? (
                          ""
                      ) : (
                          <span className="ps-1">&amp;</span>
                      )}
                    </>
                )
              }
              else {
                return (
                    <>
                      {author.name}
                      {i + 1 === arr.length ? (
                          ""
                      ) : (
                              <span className="ps-1">&amp;</span>
                          )
                      }
                    </>
                )
              }
            })}

        </div>
      </div>
    </div>
  )
}

export default BiographyBox
