/* Forum is now hosted on another server within our infrastructure
*  links to forum need to be set as external as otherwise Gatsby tries to index it
*  and keeps it within Gatsby project without sending it to the other server
*  That results in 404 page */
export const setIsForumLink = url => {
  const regex = /\/talk\/.*/
  const linkMatch = url.match(regex)
  if (linkMatch) {
    return true
  } else {
    return false
  }
}
