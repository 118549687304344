import React from "react"
import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"

import { createLocalLink } from "../../utils"
import { setIsExternalLink } from "../../utils/setIsExternalLink"
import { setIsForumLink } from "../../utils/setIsForumLink"

const Link = ({ classes, title, link, children }) => {
  const isExternal = setIsExternalLink(link)
  const isMailTo = link.match("mailto:")
  const isTel = link.match("tel:")
  const isForumLink = setIsForumLink(link)


  let isFundraisersPage
  let path

  if (typeof window !== "undefined") {
    path = global.location.pathname

    if (path.match("/fundraisers")) {
      isFundraisersPage = true
    }
  }

  if (isExternal || isMailTo || isTel || isForumLink) {
    return (
      <a
        className={classes}
        href={link}
        target={isExternal && "_blank"}
        rel="noopener noreferrer">
        {title || children}
      </a>
    )
  }

  return (
    <GatsbyLink
      className={classes}
      to={createLocalLink(link)}
      state={isFundraisersPage && { isFundraisersPage: true }}>
      {title || children}
    </GatsbyLink>
  )
}

export default Link

Link.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string
}
Link.defaultProps = {
  classes: null,
  title: null,
  link: "/"
}

