import React from "react"
import * as PropTypes from "prop-types"

const SectionHeading = ({ className, text, type="h2" }) => {
  return (text && (
      <div
          dangerouslySetInnerHTML={{
            __html: `<${type} class="${className}">${text}</${type}>`
          }}
      />
  ))
}

SectionHeading.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string
}

export default SectionHeading
