import React, { useState } from "react"

import { Container, Row, Col } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import NavWithHeaderAndText from "../components/AcfPageBlocks/NavWithHeaderAndText"
import Link from "../components/UI/Link"
import { BgImage } from "gbimage-bridge"
import { featImageClass, loadMoreBtn, imageClass } from "./stories.module.scss"

const Stories = ({ pageContext, location, history }) => {
  const { featuredStories, storiesNavData, bkgdImage } = pageContext
  const { itemsPerPage, allPosts, posts, totalElements, page, nextPagePath } =
    pageContext

  const [postsToShow, setPostsToShow] = useState(posts)
  const [loadMoreEnable, setLoadMoreEnable] = useState(
    page === 1 && itemsPerPage < totalElements
  )

  const loadMorePosts = event => {
    event.preventDefault()
    const size = postsToShow.length + itemsPerPage
    setPostsToShow([...allPosts.slice(0, size)])
    setLoadMoreEnable(page === 1 && size < totalElements)
  }

  return (
    <>
      <Layout
        seo={pageContext.seo}
        paginationComponent={{
          nextLink: pageContext.nextPagePath,
          prevLink: pageContext.prevPagePath,
          currentLink: pageContext.currentPagePath
        }}>
        <NavWithHeaderAndText
          {...storiesNavData}
          location={location}
          crumbLabel={"Stories"}
          history={history}
          pageContext={pageContext}
          headingType="h1"
        />
        <main>
          {featuredStories.length > 1 && (
            <section className="mb-4">
              <Container>
                <h2 className="pb-3">Featured Stories</h2>
                <Row>
                  {featuredStories.map((story, i) => {
                    return (
                      <Col
                        xs={12}
                        lg={6}
                        key={story.id}
                        className={i === 0 ? "feat-1 mb-7 mb-lg-0" : "feat-2"}>
                        <GatsbyImage
                          className={featImageClass}
                          image={story.image}
                          alt="{story.storiesLayouts.storyObject[0].mainImage?.altText}"
                        />
                        <Link
                          classes="linkClass lead"
                          link={story.link}
                          title={story.title}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </Container>
            </section>
          )}
          <div className="bg-pale-grey position-relative h-100 p-0">
            <BgImage image={bkgdImage} style={{ backgroundRepeat: "repeat-y" }}>
              <Container>
                <div className="position-relative h-100 px-0 py-6">
                  <Row>
                    {postsToShow.map(post => {
                      const {
                        title,
                        id,
                        slug,
                        storiesLayouts: { storyObject }
                      } = post

                      if (storyObject && storyObject.length) {
                        const { mainImage } = storyObject[0]
                        const mainImg = getImage(mainImage?.localFile)

                        return (
                          <Col
                            className="d-flex flex-column mb-4"
                            sm={12}
                            md={6}
                            lg={4}
                            key={id}>
                            <GatsbyImage
                              className={imageClass}
                              image={mainImg}
                              alt={mainImage?.altText}
                            />
                            <Link
                              classes="linkClass mt-3"
                              link={`/stories/${slug}`}
                              title={title}
                            />
                          </Col>
                        )
                      }
                    })}
                  </Row>
                </div>
                {loadMoreEnable && page === 1 && (
                  <div className="d-flex flex-column justify-content-center align-items-center pt-5 pb-6">
                    <a
                      className={loadMoreBtn}
                      onClick={loadMorePosts}
                      href={nextPagePath}>
                      Load More
                    </a>
                  </div>
                )}
                {page !== 1 && nextPagePath && (
                  <div className="d-flex justify-content-center pt-5 pb-6">
                    <a className={loadMoreBtn} href={nextPagePath}>
                      Load More
                    </a>
                  </div>
                )}
              </Container>
            </BgImage>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default Stories
