import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { titleCase, trailingPath } from "../../utils"
import SectionHeading from "../UI/SectionHeading"
import BiographyBox from "./BiographyBox"
import { makeMedicalSchema } from "../../utils/makePageSchema"
import { Helmet } from "react-helmet"

export const fragment = graphql`
  fragment navWithHeaderAndTextFragment on WpPage_Flexlayouts_FlexibleLayouts_NavWithHeaderAndText {
    sectionHeader
    headingType
    paragraph
  }
`
const NavWithHeaderAndText = ({
  sectionHeader,
  paragraph,
  location,
  pageContext,
  crumbLabel,
  headingType,
  publishedDate,
  jsonLDSchema
}) => {
  const isPageType = Boolean(!location.pathname.startsWith('/blog/'))
  const [hasBanner, setHasBanner] = useState(false)
  const isMedicalSchemaEnabled = jsonLDSchema?.medicalSchema?.enabled === 'true';
  const structuredJSON = isMedicalSchemaEnabled && makeMedicalSchema(jsonLDSchema, sectionHeader, isPageType)
  useEffect(() => {
    const banner = document.getElementById("banner")
    banner && setHasBanner(true)
  }, [])

  const topSpacing = hasBanner
    ? { marginTop: "-6px" }
    : { marginTop: "inherit" }

  const {
    breadcrumb: { crumbs }
  } = pageContext

  const cleanedCrumbs = crumbs.map(item => {
    const newLable = item.crumbLabel.replace("-", " ")
    return {
      pathname: trailingPath(item.pathname),
      crumbLabel: titleCase(newLable)
    }
  })

  if (location?.state?.isFundraisersPage) {
    cleanedCrumbs[1] = { pathname: "/fundraisers", crumbLabel: "Fundraisers" }
  }

  const cleanedLabel = titleCase(crumbLabel.replace("-", " "))
  return (
    <div
      className={hasBanner ? "pt-5 bg-pale-grey" : "pt-5 pt-lg-11 bg-pale-grey"}
      style={topSpacing}>
      <Container className="px-3 px-md-0">
        <Row className="d-flex justify-content-center">
          <Col className="d-flex flex-column">
            <Breadcrumb
              crumbs={cleanedCrumbs}
              crumbSeparator=" / "
              crumbLabel={cleanedLabel}
            />
            <Row>
              <SectionHeading className="pt-5" text={sectionHeader} type={headingType} />
            { isMedicalSchemaEnabled && (
            <div  className="d-flex">
            <>
              <BiographyBox
                jsonLDSchema={jsonLDSchema}
                publishedDate={publishedDate}
                isPageType={isPageType}
              />
              <Helmet>
                <script className='structured-data-list' type="application/ld+json">{structuredJSON}</script>
              </Helmet>
            </>
            </div>
            )}
          </Row>
            {paragraph && (
              <div
                className={`navText pb-6 pt-3 pt-lg-5`}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NavWithHeaderAndText
